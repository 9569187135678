<template>
  <a-layout id="service-big-topology-edit">
    <a-layout-header style="background: #ffffff">
      <a-button @click="
        $router.push({
          name: 'ServiceDashboardDetail',
          params: {
            id
          }
        })
      " type="link">
        <a-icon type="arrow-left"></a-icon>
        <span>退出编辑</span>
      </a-button>
      <a-space style="float: right; padding-right: 14px">
        <save-button @click="saveTopology"></save-button>
        <a-divider type="vertical" />
        <a-space :size="12">
          <div>
            <a-icon type="zoom-out" @click="zoomOut" />
          </div>
          <div style="margin: 0 0 0 0">
            <a-icon type="zoom-in" @click="zoomIn" />
          </div>
        </a-space>
      </a-space>
    </a-layout-header>

    <a-layout-content style="
          min-height: calc(100vh - 226px);
          padding: 24px;
          height: calc(100vh - 226px);
          background: #fff;
          overflow: hidden;
          display: flex;
        ">
      <div id="big-topology-edit" style="width: 100%; flex: 1"></div>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { Graph, Shape } from '@antv/x6'
import { getService, updateService } from '@/api/service'
import SaveButton from '@/components/button/SaveButton.vue'
const portAttrs = {
  circle: {
    r: 4,
    magnet: true,
    stroke: 'rgba(0, 0, 0, 0.15)',
    strokeWidth: 0.5
  }
}
const ports = {
  groups: {
    default: {
      attrs: portAttrs,
      position: {
        name: 'line',
        args: {
          start: { x: 0.5, y: 0.5 },
          end: { x: 0.5, y: 0.5 }
        }
      }
    }
  },
  items: []
}

export default {
  name: 'ServiceDashboardTopologyEdit',
  components: {
    SaveButton
  },
  data () {
    return {
      cells: [],
      id: this.$route.params.id,
      graph: null
    }
  },
  mounted () {
    this.initGraph()
    this.fetch()
  },
  methods: {
    fetch () {
      getService(this.id).then(res => {
        this.cells = res.data.topology
        this.graph.fromJSON({ cells: this.formatterData(this.cells) })
        this.graph.scaleContentToFit({ maxScale: 1.5, minScale: 0.5 })
        this.graph.centerContent()
      })
    },
    initGraph () {
      const container = document.getElementById('big-topology-edit')
      this.graph = new Graph({
        container,
        grid: true,
        autoResize: true,
        scroller: false,
        keyboard: false,
        panning: true,
        selecting: {
          enabled: true,
          multiple: false,
          filter: ['vue-shape'],
          showNodeSelectionBox: true
        },
        resizing: {
          enabled: true,
          minWidth: 100,
          minHeight: 100,
          allowReverse: false
        },
        translating: {
          restrict (view) {
            const cell = view.cell
            if (cell.isNode()) {
              const parent = cell.getParent()
              if (parent) {
                return parent.getBBox()
              }
            }
            return null
          }
        },
        connecting: {
          snap: {
            radius: 20
          },
          allowBlank: false,
          allowMulti: 'withPort',
          allowLoop: false,
          allowNode: false,
          allowEdge: false,
          createEdge () {
            return new Shape.Edge({
              attrs: {
                line: {
                  stroke: '#999',
                  strokeDasharray: 5,
                  strokeWidth: 0.5,
                  targetMarker: null,
                  style: { animation: 'topology-edge 60s infinite linear' }
                }
              }
            })
          }
        }
      })

      this.graph.on('node:collapse', ({ e, node, view }) => {
        node.toggleCollapse()
        const collapsed = node.collapsed
        const cells = node.getDescendants()
        cells.forEach(cell => {
          if (collapsed) {
            cell.hide()
          } else {
            cell.show()
          }
        })
      })
    },
    formatterData (v) {
      v.forEach(item => {
        if (item.shape === 'edge') {
          item.attrs.line.stroke = '#999'
          item.attrs.line.strokeWidth = 0.5
          item.attrs.line.targetMarker = null
        }
        if (item.shape === 'group') {
          item.attrs.button.fill = '#1890ff90'
        }
        if (item.shape === 'vue-shape') {
          item.size = { height: 48, width: 48 }
          item.ports = {
            ...ports,
            items: [{ group: 'default', id: item.id }]
          }
          // if (Array.isArray(item.ports)) {
          //   const ports = item.ports
          //   item.ports = {
          //     groups: {
          //       default: {
          //         markup: [
          //           {
          //             tagName: 'rect',
          //             selector: 'rect'
          //           }
          //         ],
          //         attrs: {
          //           rect: {
          //             magnet: true,
          //             stroke: '#31d0c6',
          //             fill: 'rgba(255,255,255,0)',
          //             strokeWidth: 0,
          //             width: 48,
          //             height: 48,
          //             y: -24
          //           }
          //         }
          //       }
          //     },
          //     items: []
          //   }

          //   ports.forEach(port => {
          //     port.group = 'default'
          //   })
          //   item.ports.items = ports
          // }
        }
      })
      return v
    },
    zoomIn () {
      this.graph.zoom(0.1)
    },
    zoomOut () {
      this.graph.zoom(-0.1)
    },
    saveTopology () {
      const topology = this.graph.toJSON()
      if (topology.cells.length) {
        topology.cells.forEach(cell => {
          if (cell.shape === 'vue-shape') delete cell.ports
        })
      }
      updateService(this.id, { topology: [...topology.cells] }).then(res => {
        this.$message.success(res.message)
      })
    }
  }
}
</script>

<style lang="less">
#service-big-topology-edit {
  height: calc(100vh - 162px);
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;

  .ant-layout-header {
    padding: 0 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
</style>
